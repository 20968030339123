import * as React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Seo from "../components/SEO"
import Button from "../components/Button/Button"
import { BlogSingleStyles } from "../components/Info/InfosStyles"


const about = () => {
  return (
    <>
      <Seo title="rules" />
      <Layout>
         <BlogSingleStyles>
            <h1 className="blogsingle__title">Общи условия</h1>           
            <article className="blogsingle__content">
              <p>Настоящата уебстраница <strong><em>/ www.venzel.bg /  </em></strong>     <strong>  НЕ Е</strong> <em>електронен магазин</em>. </p>
              <p>Тя е с информационен характер и цели да запознае потребителите с артикулите, които предлага <strong><em>Вензел ЕООД</em></strong>.</p>
              <p>Всички посочени цени в страницата ни са актуални, в български лева и с включено ДДС.</p>
              <h2 id="-">Доставка</h2>
              <p>Поръчаните продукти от Вензел ЕООД, биват доставяни с куриер до адреса, указан при заявката Ви. <strong>Плащането</strong> се извършва при самата доставка, или по банков път.</p>
              <p>Моля пазете издадената от куриера разписка! Тя е законовия документ, който получавате за направената от Вас покупка и замества издаването на касова бележка от наша страна.</p>
              <h2 id="-">Условия на доставка:</h2>
              <p>1) Продуктите, поръчани от нас се доставят с куриер до адреса, или до съответния офис, който сте посочили при поръчката си.</p>
              <p>2) Доставката, както и евентуалното връщане на поръчания артикул са за сметка на клиента. </p>
              <p>3) Доставки се извършават до всички населени места на територията на Република България.</p>
              <p>4) Заплалащането на продуктите се извършва при доставката им, или по банков път. </p>
              <p>5) Стойността на доставката се определя от куриерските услуги предлагани от <em>Econt</em>. Тя може да варира в зависимост от населеното Ви място, както и дали доставката е до офис или адрес.</p>
              <p>6) Доставки не се извършват в неделя и официални празници.</p>
              <p>7) Поръчки направени след 13:00 часа се обработват следващия работен ден. Поръчки в петък след 12:00, събота или неделя се обработват в понеделник.</p>
              <p>8) В случай, че съдържанието на пратката е повредено, или стоката не съответства с вашата поръчка, <strong><em>КЛИЕНТЪТ</em></strong> трябва да сигнализира В <strong><em>МОМЕНТА НА ОТВАРЯНЕТО</em></strong> на пратката <strong>пред самия куриер</strong>.</p>
              <h2 id="-">Срок за доставка:</h2>
              <p>Поради естеството на продуктите, които предлагаме е възможно сроковете да варират между 1 до 5 работни дни, за което уведомяваме клиентите при поръчката им.</p>
              <p>В общия случай, след получаването на поръчка я изпълняваме в рамките на 24 часа, след което бива предадена на куриер. Поръчките направени в петък след 12:00 часа, както и тези в събота и неделя, подаваме към куриер най-често във вторник /следващата седмица/. В случай на забавяне от наша страна, ще се свържем с Вас, за да Ви уведомим.</p>
              <p>В зависимост от зоната в която попада населеното място до което сте поръчали да бъде доставена пратката, срокът за доставка може да варира между 2 до 4 работни дни.</p>
              <h2 id="-">Връщане и замяна</h2>
              <p>В срок от 14 дни след получаване на стоката, клиентът има право да я върне или замени, като са спазени следните условия:</p>
              <ol>
              <li><p>Запазена оригинална опаковка (чл. 51, ал. 1, т.7 от ЗЗП) </p>
              </li>
              <li><p>Не са налице повреди по продукта. </p>
              </li>
              <li><p>Транспортните разходи за връщане на стока са за сметка на клиента.</p>
              </li>
              </ol>
              <div className="blogsingle__back">
                <Button text="Към начална страница" as={Link} to="/" />
              </div>
            </article>
          </BlogSingleStyles>
      </Layout>
    </>
  )
}

export default about
